<template>
  <div class="home">
    <!--<Banner :page_name='content_name + "-banner"' />-->
    <CKEditor :content='getContent' @submit='set_content' scope='Pagina'/>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import CKEditor from '@/components/CKEditor.vue'
import { SET_CONTENT_REQUEST } from '@/store/actions/website_content.ts'

//import Banner from '@/components/Banner.vue'

export default {
    name: 'home',
    data() {
        return {
            content_name: 'homepage',
            mounted: false,
            content_loaded: false
        }
    },
    components: {
        CKEditor,
        //Banner
    },
    mounted: function() {
        this.mounted = true
    },
    methods: {
        set_content: function(new_content) {
            this.$store.dispatch(SET_CONTENT_REQUEST, {name: this.content_name, content: new_content}).then()
        },
    },
    computed: {
        getContent: function() {
            if (!this.mounted) return
            let store = this.$store.state.website_content.content
            if (!(this.content_name in store)) return this.$store.state.website_content.status
            return store[this.content_name]
        },
    },
}
</script>

<style scoped>
a {
    color: #42b983;
}
</style>
